import { Helmet } from "react-helmet";
import { SWLink } from "../../components/components";
import GrassEvent from "./GrassEvent";
import { GrassList } from "./GrassList";
function Grass() {
	return (
		<div className="content">
			<Helmet>
				<title>Grass Volleyball</title>
				<meta name="description" content="Grass Volleyball Tournaments" />
			</Helmet>
			<h1>Grass Volleyball Tournaments</h1>
			<h5>Upcoming Tournaments:</h5>
			<div style={{ display: "flex", flexWrap: "wrap" }}>
				{GrassList.filter((e) => e.visible == true && e.past != true).map(
					(e) => {
						return <GrassEvent event={e} />;
					}
				)}
			</div>
			<h5>Past Tournaments:</h5>
			<div style={{ display: "flex", flexWrap: "wrap" }}>
				{GrassList.filter((e) => e.visible == true && e.past == true).map(
					(e) => {
						return <GrassEvent event={e} />;
					}
				)}
			</div>
		</div>
	);
}
export default Grass;
