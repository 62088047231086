import "./style.css";

import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import APVFAQs from "./Helpful/FAQs/APVFAQs";
import About from "./AboutUs/About";
import BoysTryouts from "./Play/Seasons/BoysTryouts/BoysTryouts";
import Contact from "./AboutUs/Contact/contact";
import FAQs from "./Helpful/FAQs/FAQs";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import { Helmet } from "react-helmet";
import Home from "./Home/Home";
import Links from "./Helpful/Links";
import PageNotFound from "./PageNotFound";
import Practices from "./Helpful/Practices/Practices";
import SafeSport from "./Helpful/SafeSport/SafeSport";
import Staff from "./AboutUs/Staff/Staff";
import Store from "./Apparel/Store";
import StoreCancel from "./Apparel/StoreCancel";
import StoreSuccess from "./Apparel/StoreSuccess";
import SummerClinic from "./Play/Summer/SummerClinic";
import Teams from "./AboutUs/Teams/Teams";
import Tournaments from "./Helpful/Tournaments/Tournaments";
import TryoutProcess from "./Play/TryoutProcess/TryoutProcess";
import Tryouts from "./Play/Seasons/GirlsTryouts/Tryouts";
import { useEffect } from "react";
import Club from "./Play/Club";
import Grass from "./Play/Grass/Grass";

export const MyRoutes = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			{/* About Us */}
			<Route path="/about" element={<About />} />
			<Route path="/contact" element={<Contact />} />
			<Route path="/staff/" element={<Staff />} />
			<Route path="/staff/:selSeason/:selCoach" element={<Staff />} />
			<Route path="/staff/:selYear/:selSeason/:selCoach" element={<Staff />} />
			<Route path="/teams" element={<Teams />} />
			<Route path="/teams/:selSeason" element={<Teams />} />
			<Route path="/teams/:selSeason/:selTitle" element={<Teams />} />
			<Route path="/teams/:selYear/:selSeason/:selTitle" element={<Teams />} />
			{/* Play */}
			<Route path="/club" element={<Club />} />
			<Route path="/tryouts" element={<Tryouts />} />
			<Route path="/boystryouts" element={<BoysTryouts />} />
			<Route path="/boystryouts/:selSeason" element={<BoysTryouts />} />
			<Route path="/tryoutprocess" element={<TryoutProcess />} />
			<Route path="/summerclinics" element={<SummerClinic />} />
			<Route path="/grass" element={<Grass />} />
			{/* Helpful Info */}
			<Route path="/faqs" element={<FAQs />} />
			<Route path="/apvfaqs" element={<APVFAQs />} />
			<Route path="/practices" element={<Practices />} />
			<Route path="/tournaments" element={<Tournaments />} />
			<Route path="/safesport" element={<SafeSport />} />
			<Route path="/links" element={<Links />} />
			{/* Store */}
			<Route path="/apparel" element={<Store />} />
			<Route path="/store" element={<Store />} />
			<Route path="/storecancel" element={<StoreCancel />} />
			<Route path="/storesuccess" element={<StoreSuccess />} />
			{/* 404 Error */}
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
};

function App() {
	useEffect(() => {
		const script = document.createElement("script");

		script.src = "https://w.behold.so/widget.js";
		script.type = "module";
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<div className="App">
			<Helmet>
				<title>Sidewinder Volleyball Academy</title>
				<meta charSet="utf-8" />
				<meta
					name="description"
					content="A Tucson, Arizona Volleyball Club.  Tucson volleyball training, clinics, camps and club teams."
				/>
			</Helmet>
			<div className="mainContainer">
				{/* <div className='topBanner' style={{display:'flex', justifyContent:"center"}}>
          <h4 style={{textAlign:"center"}}>15u-17u tryouts are at ASDB.  Times are posted on the <a href="/tryouts">tryout page</a></h4>
        </div> */}
				<Router>
					<Header></Header>
					<div className="appContainer">
						<MyRoutes />
					</div>
					<Footer />
				</Router>
			</div>
		</div>
	);
}

export default App;
