export const GrassList = [
	{
		title: "Co-Ed Quads",
		teams: "2 girls, 2 guys",
		group: "Adults and Juniors",
		when: "Saturday, February 8th",
		where: "Rillito Regional Park, Field #7",
		registerurl:
			"https://docs.google.com/forms/d/e/1FAIpQLScV2pvNDI7CgHJJ-P7eiPNn98QXkNWW3oyVWI2J4dngK-CKfw/viewform?usp=header",
		resultsurl:
			"https://docs.google.com/spreadsheets/d/1-zMwt287I6iMWVcwdlzIyIE2CZXSc4a_KgiP65qAl3Q/edit?usp=sharing",
		visible: true,
		past: true,
	},
	{
		title: "Co-Ed Quads",
		teams: "2 girls, 2 guys",
		group: "Adults and Juniors",
		when: "Sunday, March 9th",
		where: "Rillito Regional Park",
		registerurl:
			"https://docs.google.com/forms/d/e/1FAIpQLScsnseS99dkHMG1OZ3AtM1GfcJ5FUA_KBE6zo2HmFtoiyq26Q/viewform?usp=dialog",
		resultsurl: null,
		visible: true,
	},
	{
		title: "TBD",
		teams: "TBD",
		group: "Adults",
		when: "Sunday, March 16th",
		where: "Location TBD",
		registerurl: null,
		resultsurl: null,
	},
	{
		title: "TBD",
		teams: "TBD",
		group: "Adults",
		when: "Sunday, March 30th",
		where: "Location TBD",
		registerurl: null,
		resultsurl: null,
	},
	{
		title: "TBD",
		teams: "TBD",
		group: "Adults",
		when: "Sunday, April 27th",
		where: "Location TBD",
		registerurl: null,
		resultsurl: null,
	},
	{
		title: "TBD",
		teams: "TBD",
		group: "Adults",
		when: "Saturday, May 10th",
		where: "Location TBD",
		registerurl: null,
		resultsurl: null,
	},
	{
		title: "Co-Ed Quads",
		teams: "2 girls, 2 guys",
		group: "Adults and Juniors",
		when: "Saturday, May 31st",
		where: "Location TBD",
		registerurl: null,
		resultsurl: null,
	},
];
