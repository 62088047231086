import { Helmet } from "react-helmet";
import { SWLink } from "../components/components";
function Club() {
	return (
		<div className="content">
			<Helmet>
				<title>Club Volleyball</title>
				<meta name="description" content="Club Volleyball Options" />
			</Helmet>
			<h1>Club Volleyball Seasons</h1>
			<div
				style={{
					display: "flex",
					flexWrap: "wrap",
				}}
			>
				<div style={{ margin: "0 16px" }}>
					<h2>Girls</h2>
					<h5>November - May: Region Teams</h5>
					<h5>November - July: Travel Teams</h5>
					<SWLink to={"/tryouts"} content={"Learn More"} />
				</div>
				<div style={{ margin: "0 16px" }}>
					<h2>Boys</h2>
					<h5>
						August - January:{" "}
						<SWLink to={"/boystryouts/fall"} content={"Fall Season"} />
					</h5>
					<h5>
						January - May:{" "}
						<SWLink to={"/boystryouts/spring"} content={"Spring Season"} />{" "}
						*10u-14u age groups only
					</h5>
					<h5>
						May - July:{" "}
						<SWLink to={"/boystryouts/summer"} content={"Summer Season"} />
					</h5>
				</div>
			</div>
		</div>
	);
}
export default Club;
