import { Link, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import Fall from "./Fall";
import { Helmet } from "react-helmet";
import Spring from "./Spring";
import Summer from "./Summer";
import boysteam from "../../../components/img/heroimgs/boysteam.png";

function BoysTryouts() {
	const [season, setSeason] = useState({
		name: "Spring 2025 (10u-14u)",
		data: <Spring />,
	});
	const seasons = [
		{ name: "Fall 2024 (All Ages)", data: <Fall /> },
		{ name: "Spring 2025 (10u-14u)", data: <Spring /> },
		{ name: "Summer 2025 (All Ages)", data: <Summer /> },
	];

	const { selSeason } = useParams();
	useEffect(() => {
		if (selSeason) {
			if (selSeason.toLowerCase() === "fall") {
				setSeason({ name: "Fall 2024 (All Ages)", data: <Fall /> });
			} else if (selSeason.toLowerCase() === "spring") {
				setSeason({ name: "Spring 2025 (10u-14u)", data: <Spring /> });
			} else if (selSeason.toLowerCase() === "summer") {
				setSeason({ name: "Summer 2025 (All Ages)", data: <Summer /> });
			}
		}

		return () => {};
	}, []);

	return (
		<div className="content">
			<Helmet>
				<title>Boy's Tryouts</title>
				<meta
					name="description"
					content="Get registered for our youth volleyball club season."
				/>
			</Helmet>
			<h1>Boy's Volleyball</h1>
			<div style={{ textAlign: "center" }}>
				<div className="pageHero">
					<img src={boysteam} />
				</div>
			</div>
			<h3>What is the process to tryout?</h3>
			<p>
				USAV has a strict process on how to prepare and sign up for tryouts.{" "}
				<Link to="/tryoutprocess">Follow this guide to sign up!</Link>
			</p>
			<p>
				Make sure you show up at least 15 minutes early to tryouts for check in!
			</p>
			<h3>What Age Should I Tryout For?</h3>
			<p>
				We have a team for every age group from 12u-18u. Some age groups have
				multiple teams. Boys younger than 12 are still welcome to join! We have
				had boys as young as 9 play in past seasons.
			</p>
			<p>
				Check out{" "}
				<a
					href="https://www.azregionvolleyball.org/agedefinitions"
					target="_blank"
					rel="noopener noreferrer"
				>
					this page
				</a>{" "}
				to calculate your age group.
			</p>
			<h3>Why are there multiple seasons?</h3>
			<p>
				We start each season with tryouts in August.{" "}
				<strong>
					<u>Fall</u>
				</strong>{" "}
				season runs August - December.
				<br />
				<br />
				High School boys play with their school team in the Spring, but middle
				school boys can continue playing{" "}
				<strong>
					<u>Spring</u>
				</strong>{" "}
				club January-May.
				<br />
				<br />
				We hold{" "}
				<strong>
					<u>Summer</u>
				</strong>{" "}
				tryouts in June to re-make teams before going to the National
				Championships in July.
			</p>
			<h1>Info by Season</h1>
			<div style={{ textAlign: "center" }}>
				{seasons.map((s) => {
					return (
						<button
							className={"coachButton" + (season.name === s.name ? " sel" : "")}
							onClick={() => setSeason(s)}
						>
							{s.name}
						</button>
					);
				})}
			</div>
			{season.data}
		</div>
	);
}
export default BoysTryouts;
