import React from "react";

const Spring = () => {
	return (
		<div>
			<div style={{ textAlign: "center" }}>
				<h1>2025 Spring Season</h1>
				<h3 style={{ color: "red" }}>
					We are still looking for a few more 7th/8th grade boys for our 2025
					Spring teams! Email us to join!
				</h3>
			</div>
			<h3>What does the Spring season look like?</h3>
			<h5>Early January - End of April</h5>
			<div style={{ textAlign: "left" }}>
				<p>
					Spring Season is only for athletes 14 or younger. High school athletes
					compete with their high school team during this time.
				</p>
				<p>
					There are no tryouts for Spring Season boys. All of the boys will
					practice together for a few weeks before being seperated into teams
					for the first tournament. Spring season is made up of 4 AZ Region
					tournaments, typically in Phoenix.
				</p>
			</div>
			<h3>Tournaments Attended</h3>
			<ul style={{ listStyle: "none" }}>
				<li>AZ Region #1 - February 22nd</li>
				<li>AZ Region #2 - March 8th</li>
				<li>AZ Region #3 - March 22th</li>
				<li>AZ Region #4 - April 5th</li>
			</ul>
			<h3>Costs</h3>
			<p>
				Tryouts are $25. For players who played in the fall, dues will be
				structured as shown below. New players will have an additional $100
				uniform fee
			</p>
			<table>
				<thead>
					<tr>
						<td>Due Date</td>
						<td>Monthly Plan</td>
						<td>Pay In Full Plan</td>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Feb 1st</td>
						<td>$350</td>
						<td>$1000</td>
					</tr>
					<tr>
						<td>Mar 1st</td>
						<td>$350</td>
						<td>$0</td>
					</tr>
					<tr>
						<td>Apr 1st</td>
						<td>$350</td>
						<td>$0</td>
					</tr>
					<tr className="boldrow">
						<td>Total</td>
						<td>$1050</td>
						<td>$1000</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
};

export default Spring;
