export const SchoolCoaches = [
	{
		name: "Catalina Foothills (Boys)",
		coaches: [
			{
				title: "Varsity",
				link: "/staff/24-25/Boys-Spring/andrewadair",
			},
			{ title: "JV-A", link: "/staff/24-25/Boys-Fall/franklindonley" },
			{ title: "JV-B", link: "/staff/24-25/Boys-Fall/julissaserna" },
		],
	},
	{
		name: "Catalina Foothills (Girls)",
		coaches: [
			{
				title: "Varsity",
				link: "/staff/24-25/Boys-Spring/andrewadair",
			},
			{ title: "JV-A", link: "/24-25/Boys-Fall/keiferneerhof" },
			{ title: "JV-B", link: "/staff/24-25/Boys-Fall/aydafelix" },
		],
	},
	{
		name: "Catalina Foothills (Beach)",
		coaches: [
			{ title: "Varsity", link: "/staff/24-25/Boys-Fall/brianbowers" },
			{ title: "JV", link: "/staff/24-25/Girls/samgillman" },
		],
	},
	{
		name: "Cienega (Boys)",
		coaches: [
			{ title: "Boys Varsity", link: "/staff/24-25/Boys-Fall/heathermott" },
			{ title: "JV-A", link: "/staff/24-25/Boys-Fall/laurentriel" },
			{ title: "JV-B", link: "/staff/24-25/Boys-Fall/aurorachagollan-molina" },
		],
	},
	{
		name: "Cienega (Girls)",
		coaches: [{ title: "Varsity", link: "/staff/24-25/Girls/mateavillegas" }],
	},
	{
		name: "Salpointe (Boys)",
		coaches: [
			{ title: "Varsity", link: "/staff/24-25/Boys-Fall/stephenhuff" },
			{ title: "JV-A", link: "/staff/24-25/Boys-Spring/aydafelix" },
			{ title: "JV-B", link: "/staff/24-25/Boys-Spring/layarocha" },
		],
	},
	{
		name: "Tucson High (Boys)",
		coaches: [
			{ title: "Varsity", link: "/staff/24-25/Girls/mateavillegas" },
			{ title: "JV-A", link: "/staff/24-25/Girls/ruebenbautista" },
		],
	},
	{
		name: "Marana (Boys)",
		coaches: [
			{ title: "Varsity", link: "/staff/24-25/Boys-Fall/jaredchapman" },
		],
	},
	{
		name: "Marana (Girls)",
		coaches: [{ title: "JV-A", link: "/staff/24-25/Girls/adrianadewitt" }],
	},
	{
		name: "Mica Mountain (Boys)",
		coaches: [{ title: "Varsity", link: "/staff/24-25/Boys-Fall/janedevries" }],
	},
	{
		name: "Santa Rita (Girls)",
		coaches: [
			{ title: "Varsity", link: "/staff/24-25/Boys-Fall/cheyennerice" },
		],
	},
	{
		name: "St Augustine (Girls)",
		coaches: [{ title: "Varsity", link: "/staff/24-25/Boys-Spring/layarocha" }],
	},
];
