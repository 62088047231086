import "./grass.css";
function GrassEvent({ event }) {
	return (
		<div className="tournament-card">
			<h2>{event.title}</h2>
			<h5>{event.group}</h5>
			<h5>{event.teams}</h5>
			<br />
			<h5>{event.when}</h5>
			<h5>{event.where}</h5>
			{event.past == true ? null : (
				<>
					<h3 style={{ marginTop: "16px" }}>Register:</h3>
					{event.registerurl ? (
						<a
							href={event.registerurl}
							target="_blank"
							rel="noopener noreferrer"
						>
							Click to Register
						</a>
					) : (
						<p>Coming soon</p>
					)}
				</>
			)}
			<h3 style={{ marginTop: "16px" }}>
				{event.past == true ? "Results:" : "Schedule:"}
			</h3>
			{event.resultsurl ? (
				<a href={event.resultsurl} target="_blank" rel="noopener noreferrer">
					View Results
				</a>
			) : (
				<p>Available on Tournament Day</p>
			)}
		</div>
	);
}
export default GrassEvent;
