import "./Staff.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { useEffect, useState } from "react";

import { Carousel } from "react-responsive-carousel";
import CoachHeader from "./CoachHeader";
import CoachView from "./CoachView";
import Coaches from "./Coaches";
import { Helmet } from "react-helmet";
import { TeamList } from "../Teams/TeamList";
import { useParams } from "react-router-dom";

function Staff() {
	let { selSeason, selCoach } = useParams();
	const selSub =
		selSeason && selSeason.includes("-") ? selSeason.split("-")[1] : null;
	selSeason =
		selSeason && selSeason.includes("-") ? selSeason.split("-")[0] : selSeason;

	const [slide, setSlide] = useState(0);

	const [year, setYear] = useState(
		selSeason && selSeason == "Girls" ? "24-25" : "24-25"
	);
	const [season, setSeason] = useState(selSeason ?? "Boys");
	const [sub, setSub] = useState("Fall");

	const teams = TeamList.filter(
		(t) =>
			t.season === season &&
			(season === "Boys" ? t.sub === "Fall" || t.sub === "Spring" : true) &&
			t.year === year
	).sort((a, b) => (a.age > b.age ? -1 : 1));

	const directors = [Coaches.aadair, Coaches.kwells];

	let headNames = [];
	const hCoachArrs = [
		...teams.map((t) => {
			return t.heads
				? t.heads
						.filter((c) => c.type !== "Director")
						.map((c) => {
							if (!headNames.includes(c.name)) {
								headNames.push(c.name);
								c.team = t;
								return c;
							} else {
								return null;
							}
						})
				: [];
		}),
	]
		.flat(1)
		.filter((c) => c !== null);

	let assNames = [];
	const aCoachArrs = [
		...teams.map((t) => {
			return t.assistants
				? t.assistants
						.filter((c) => c.type !== "Director")
						.map((c) => {
							if (!assNames.includes(c.name)) {
								assNames.push(c.name);
								c.team = t;
								return c;
							} else {
								return null;
							}
						})
				: [];
		}),
	]
		.flat(1)
		.filter((c) => c !== null);

	useEffect(() => {
		const allCoaches = [...directors, ...hCoachArrs, ...aCoachArrs];

		if (selCoach !== undefined) {
			const i = allCoaches.findIndex((coach) => {
				return (
					coach.name.replaceAll(" ", "").toLowerCase() ===
					selCoach.toLowerCase().replaceAll(",", "")
				);
			});

			setSlide(i);
		}

		return () => {};
	}, []);

	return (
		<div className="content">
			<Helmet>
				<title>Coaches and Staff</title>
				<meta
					name="description"
					content="Our directors, coaches and staff at Sidewinder Volleyball Academy have years of experience with youth volleyball in Tucson, AZ."
				/>
			</Helmet>
			<h1>Our Staff</h1>
			<CoachHeader
				slide={slide}
				setSlide={setSlide}
				season={season}
				setSeason={setSeason}
				sub={sub}
				setSub={setSub}
				directors={directors}
				headCoaches={hCoachArrs}
				assistCoaches={aCoachArrs}
			/>
			<hr />
			<div>
				<Carousel
					showThumbs={false}
					showArrows={false}
					showIndicators={false}
					infiniteLoop={true}
					selectedItem={slide}
					swipeScrollTolerance={50}
					preventMovementUntilSwipeScrollTolerance={true}
					onChange={(result) => {
						if (result != slide) {
							setSlide(result);
						}
					}}
				>
					{[...directors, ...hCoachArrs, ...aCoachArrs]
						.flat(1)
						.map((coach, index) => {
							return (
								<CoachView
									coach={coach}
									season={season}
									sub={sub}
									key={coach.name}
									teams={teams}
								/>
							);
						})}
				</Carousel>
			</div>
		</div>
	);
}

export default Staff;
