import "./home.css";

import { Link, useLocation } from "react-router-dom";
import { SWButton, SWLink } from "../components/components";

import { Carousel } from "react-responsive-carousel";
import { TeamList } from "../AboutUs/Teams/TeamList";
import body from "../components/img/icon-body.png";
import whoweare from "../components/img/heroimgs/whoweare.jpg";
import mind from "../components/img/icon-mind.png";
import spirit from "../components/img/icon-spirit.png";
import staff from "../components/img/heroimgs/staff.jpg";
import { useEffect } from "react";
import video from "../components/img/webvideo.mp4";
import ourteams from "../components/img/heroimgs/ourteams.JPG";
import y2023t16 from "../components/img/teams/2023-16.jpg";

const HeroImgs = () => {
	return (
		<div className="heroImgs">
			<a
				href="/about"
				className="heroImg"
				style={{ backgroundImage: `url(${whoweare})` }}
			>
				<h2>Who We Are</h2>
			</a>
			<a
				href="/staff"
				className="heroImg"
				style={{ backgroundImage: `url(${staff})` }}
			>
				<h2>Our Staff</h2>
			</a>
			<a
				href="/teams"
				className="heroImg"
				style={{ backgroundImage: `url(${ourteams})` }}
			>
				<h2>Our Teams</h2>
			</a>
		</div>
	);
};

function Home() {
	const location = useLocation();
	useEffect(() => {
		if (window.beholdWidgets !== undefined) {
			window.beholdWidgets.initialize();
		}
	}, [location]);

	return (
		<div
			style={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
			}}
		>
			<div className="landingcontainer">
				<div className="landingimg">
					<video
						src={video}
						autoPlay={true}
						muted
						controls={false}
						loop={true}
						playsInline
					/>
				</div>
				<div className="imgoverlay">
					{/* <img src={logored} alt="Sidewinder Volleyball Snake Logo" /> */}
					<div
						style={{ backgroundColor: "white", width: "200px", height: "10%" }}
					></div>
					<h1>Sidewinder Volleyball Academy</h1>
					<h5>Tucson's Premier Volleyball Club</h5>
					<Link to={"/contact"}>
						<SWButton content={"Contact Us"} />
					</Link>
				</div>
			</div>
			<div className="content" style={{ textAlign: "center" }}>
				<HeroImgs />
				<hr />
				{/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{ marginBottom: 0 }}>Register for Tryouts!</h1>
          <h4 style={{ marginBottom: 4 }}>Girl's Tryouts</h4>
          <p style={{ margin: 0 }}>
            Tryouts on November 10th (14 and under) and November 30th (15-18u).{" "}
            <a href="/tryouts">REGISTER HERE</a>
          </p>
          <h4 style={{ marginBottom: 4 }}>Boy's SPRING Season</h4>
          <p style={{ margin: 0 }}>
            Spring season starts in January for boys who are NOT in high school
            yet. High School boys are playing with their school team during the
            spring. Send us an email if you want more info on the boy's spring
            season. Director@SidewinderVA.com
          </p>
        </div>
        <hr /> */}
				<h1>
					Follow us on{" "}
					<a
						href="https://www.instagram.com/sidewinder_va"
						target="_blank"
						rel="noopener noreferrer"
					>
						Instagram!
					</a>
				</h1>
				<figure data-behold-id="X955tRQNhaRc8RDAfQGI"></figure>
				<hr />
				<h1 style={{ margin: "32px 0" }}>
					At Sidewinder, we train the whole athlete.
				</h1>
				<div className="trainwhole">
					<div className="trainpart">
						<img src={mind} className="homeicon" alt="heart icon" />
						<h3>Mind</h3>
						<p>
							We take pride in our club's unique atmosphere, one that fosters a
							true sense of family within a highly competitive environment.
						</p>
					</div>
					<div className="trainpart">
						<img src={body} className="homeicon" alt="player icon" />
						<h3>Body</h3>
						<p>
							We strive to teach the fundamentals of volleyball at their highest
							level, to maximize the potential of each of our athletes.
						</p>
					</div>
					<div className="trainpart">
						<img src={spirit} className="homeicon" alt="confetti icon" />
						<h3>Spirit</h3>
						<p>
							The ideals of hard work and family are the bedrock of our club,
							and will continue to be what separates our club from the rest.
						</p>
					</div>
				</div>
				<hr />
			</div>
			<a href="/teams">
				<div className="teamImages">
					<Carousel
						swipeable={false}
						autoPlay={true}
						showThumbs={false}
						showArrows={false}
						showIndicators={false}
						infiniteLoop={true}
						transitionTime={1000}
						interval={4000}
						// animationHandler={"fade"}
					>
						{TeamList.filter((t) => t.year === "24-25")
							.filter((t) => (t.image ? true : false))
							.sort((a, b) =>
								a.age === b.age ? a.rank - b.rank : b.age - a.age
							)
							.map((team, index) => {
								return (
									<div className="teamSlide">
										<img
											src={team.image.src}
											alt={`${team.year} ${team.season} ${team.teamName} athletes`}
										/>
										<h4>
											{team.year} {team.season} {team.teamName}{" "}
											{TeamList.filter((t) => t.year === "24-25")
												.filter((t) => (t.image ? true : false))
												.filter(
													(t) =>
														t.teamName == team.teamName &&
														t.season == team.season
												).length > 1
												? `(${team.sub})`
												: ""}
										</h4>
									</div>
								);
							})}
					</Carousel>
					<h2>View All Teams</h2>
				</div>
			</a>
		</div>
	);
}
export default Home;
