import "../Home/home.css";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import body from "../components/img/icon-body.png";
import families from "../components/img/heroimgs/families.png";
import kyle from "../components/img/coaches/kyle.jpg";
import mind from "../components/img/icon-mind.png";
import spirit from "../components/img/icon-spirit.png";
import y2023t16 from "../components/img/teams/2023-16.jpg";
import { SchoolCoaches } from "./SchoolCoachList";
import { SWLink } from "../components/components";

function About() {
	return (
		<div className="content">
			<Helmet>
				<title>About Us</title>
				<meta
					name="description"
					content="Sidewinder Volleyball Academy is a youth volleyball club servicing the
        Tucson, AZ area."
				/>
			</Helmet>
			<h1>Who We Are</h1>
			<h4>
				Sidewinder Volleyball Academy is a youth volleyball club servicing the
				Tucson, AZ area.
			</h4>
			<div className="pageHero">
				<img src={families} />
			</div>
			<p>
				Our coaching staff includes the coaches of top high school programs
				including:
				<span style={{ display: "flex", justifyContent: "center" }}>
					<ol
						style={{
							listStyle: "none",
							textAlign: "left",
							padding: "0px",
						}}
					>
						{SchoolCoaches.sort((a, b) => (a.name > b.name ? 1 : -1)).map(
							(s) => {
								return (
									<li>
										<strong>{s.name} </strong>
										{s.coaches.map((c, i) => {
											return (
												<>
													<SWLink to={c.link} content={c.title} />
													{i < s.coaches.length - 1 ? ", " : ""}
												</>
											);
										})}
									</li>
								);
							}
						)}
					</ol>
				</span>
				Read about all of our coaches <a href="/staff">here</a>.
			</p>
			<h3>What We Believe</h3>
			<div className="trainwhole">
				<div className="trainpart">
					<img src={mind} className="homeicon" />
					<h3>Mind</h3>
					<p>
						We take pride in our club's unique atmosphere, one that fosters a
						true sense of family within a highly competitive environment.
					</p>
				</div>
				<div className="trainpart">
					<img src={body} className="homeicon" />
					<h3>Body</h3>
					<p>
						We strive to teach the fundamentals of volleyball at their highest
						level, to maximize the potential of each of our athletes.
					</p>
				</div>
				<div className="trainpart">
					<img src={spirit} className="homeicon" />
					<h3>Spirit</h3>
					<p>
						The ideals of hard work and family are the bedrock of our club, and
						will continue to be what separates our club from the rest.
					</p>
				</div>
				<p>
					<strong>
						The focus of youth sports should not be winning and losing.
					</strong>{" "}
					Rather, we are focused on creating a fun, competitive atmosphere where
					we can teach the fundamentals. We believe that learning how to
					continually better yourself as an athlete and person is the most
					important factor to success on and off the court.
				</p>
			</div>
			<h3>What We Offer</h3>
			<div
				style={{
					display: "flex",
					alignItems: "center",
					flexDirection: "column",
				}}
			>
				<a
					href="/tryouts"
					className="heroImg"
					style={{
						backgroundImage: `url(${y2023t16})`,
						width: "100%",
						maxWidth: "400px",
					}}
				>
					<h2>Club Teams</h2>
				</a>
				<p>
					We offer competitive travel teams and developmental teams for younger
					athletes. Travel teams are either regional (competing in Phoenix and
					Tucson tournaments only) or national (competing in USAV national
					tournaments).
				</p>
				<a
					href="/summerclinics"
					className="heroImg"
					style={{
						backgroundImage: `url(${kyle})`,
						width: "100%",
						maxWidth: "400px",
					}}
				>
					<h2>Summer Clinics</h2>
				</a>
				<p>
					We offer weekly summer clinics for athletes looking to improve
					specific skills. Each week covers a different skill including serving,
					passing, settings, attacking, blocking and defense. These clinics are
					for middleschool and highschool athletes - we start at the fundaments
					of each skill and work up to including them into full gameplay.
				</p>
			</div>
		</div>
	);
}
export default About;
